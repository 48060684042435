import React from 'react';
import '../css/style-app.css';

export default function Contact(props){
   // console.log("Contact", props); 
    const message = props.content.message; 
    const whatsapp =  props.content.whatsapp;
    const telegram =  props.content.telegram;
    const instagram =  props.content.instagram;
    const youtube =  props.content.youtube;

    //<h3>{title}</h3> 
    return  (
        <div className="social color-light">
                    
                   
                    <p>{message}</p>
                    <p className="separator"> | </p>
                    <div className="social-link text-muted">
                        {youtube && 
                        <a href={`https://youtube.com/@${youtube}`}  target="_blank" rel="noreferrer noopener">
                            <i className="fab fa-youtube"></i>
                        </a>
                        }
                        {whatsapp &&
                        <a href={`https://wa.me/${whatsapp}`}  target="_blank" rel="noreferrer noopener">
                            <i className="fab fa-whatsapp"></i>
                        </a>
                        }
                        {telegram &&
                        <a href={`https://t.me/${telegram}`} target="_blank" rel="noreferrer noopener">
                            <i className="fab fa-telegram-plane"></i>
                            </a>
                        }
                        {instagram && 
                        <a href={`https://www.instagram.com/${instagram}`}  target="_blank" rel="noreferrer noopener">
                            <i className="fab fa-instagram"></i>
                        </a>
                        }
                    </div>

        </div>
    )
}
