import React from 'react';
import '../css/style-app.css';
import ButtonAction from './ButtonAction';
import Feature from './Feature';

export default function ContainerGrid(props) {

    const { title, subtitle } = props.section;
    const { message } = props.section;
    const { image } = props.section;
    const { buttonAction } = props.section;
    const { buttonUrl } = props.section;
    const { list } = props.section;
    const { position } = props;

    console.log(`containerGrid: ${title}`, props.section);

    let classSection = "container-row color-light";
    if (position % 2 !== 0) {
        classSection = "container-row";
    }

    const classContainerSection = "container-section";

    const sectionHtml = (
        <div className={classSection}>
            <div className={classContainerSection}>
                <section className="section">
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                    <p className="section-p">{message}</p>
                    <div className="grid">
                        {
                            list.map((feature, index) => (
                                <div className="grid-cell" key={index}>
                                    <Feature key={index} feature={feature} />
                                </div>
                            ))
                        }
                    </div>
                    <div>
                        {
                            buttonAction ?
                                <ButtonAction action={buttonAction} url={buttonUrl} />
                                : ""
                        }
                    </div>
                </section>
            </div>
        </div>
    )



    return (sectionHtml)
}
