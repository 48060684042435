import React from 'react'
import '../css/style-app.css';
export default function YoutubeVideo(props) {
    const embedId = props.embedId;

  return (
    <div className='youtube'>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?showinfo=0&controls=0&modestbranding&rel=0&iv_load_policy=3`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  </div>  

  )
}
