import React from 'react';
import '../css/style-app.css';
import ButtonAction from './ButtonAction';
import ListFeatures from './ListFeatures';
import YoutubeVideo from './YoutubeVideo';

export default function ContainerLandscape(props) {

    const { title, subtitle } = props.section;
    const { message } = props.section;
    const {youtube } = props.section;
    const { buttonAction } = props.section;
    const { buttonSecundary } = props.section;
    const { buttonUrl } = props.section;
    const { list } = props.section;
    const { position } = props;

  
    let classContainer = "container-row color-light";
    if (position % 2 !== 0) {
        classContainer = "container-row";
    }

    const buttonHtml = buttonAction ? (<ButtonAction action={buttonAction} url={buttonUrl} />) : "";

    const sectionHtml = (
        <div className={classContainer}>
            <div className="container-landscape">
                <section className="section section-small">
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                    <p className="section-p">{message}</p>
                    {list &&
                        <ListFeatures list={list} />
                    }
                    <div>
                        {
                            buttonAction ?
                                <ButtonAction action={buttonAction} url={buttonUrl} />
                                : ""
                        }
                    </div>
                </section>

           
                <div className="section-large">
                     { <YoutubeVideo embedId={youtube} /> } 
                </div>
            </div>    
        </div>
    )



    //console.log(`breakSection: ${breakSection}, position:${position}`); 


    return (sectionHtml)
}
