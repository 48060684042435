import React from 'react';
import '../css/style-app.css';
import ButtonAction from './ButtonAction';
import ListFeatures from './ListFeatures';
import YoutubeVideo from './YoutubeVideo';

export default function ContainerRow(props) {

    const { title, subtitle } = props.section;
    const { message } = props.section;
    const { image, video } = props.section;
    const { buttonAction } = props.section;
    const { buttonSecundary } = props.section;
    const { buttonUrl } = props.section;
    const { list } = props.section;
    const { backgroundColor } = props.section;
    const { imageStyle, imageOrientation } = props.section;
    const { position } = props;

    



    let imageCard=""
    if(imageStyle=="box-card-shadow"){
        imageCard="box-card-shadow"
    }else if(imageStyle=="box-card-primary"){
        imageCard="box-card-primary"
    }



    let color = " color-light";
    let colorText=""
    let classContainer = "container-row";
    if (position % 2 !== 0) {
        color=""
        classContainer = "container-row";
    }
    if(backgroundColor=="primary"){
        color="color-primary"
        colorText="color-on-primary"
    }
    classContainer = `${classContainer} ${color}`
    let classContainerSection = "container-section";



    if (position % 2 !== 0) {
        classContainerSection = "container-section-reverse"
    }

    let sectionClass = "section-large"
    let imageSectionClass = "section-small"
    let imageClass = "image-v"
    if(imageOrientation==="horizontal"){
        sectionClass = "section-small"
        imageSectionClass = "section-large"
        imageClass = "image-h"
    }

    const buttonHtml = buttonAction ? (<ButtonAction action={buttonAction} url={buttonUrl} />) : "";

    const sectionHtml = (
        <div className={classContainer}>
            <div className={classContainerSection}>
                <section className={`section ${sectionClass}`}>
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                    <p className={`section-p ${colorText}`}>{message}</p>
                    {list &&
                        <ListFeatures list={list} />
                    }
                    <div>
                        {
                            buttonAction ?
                                <ButtonAction action={buttonAction} url={buttonUrl} />
                                : ""
                        }
                    </div>
                </section>
                <div className={imageSectionClass}>
                    {image ?
                     <img className={`${imageClass} ${imageCard}`} src={image} alt={title} />
                        : ""
                    }

                </div>
            </div>
        </div>
    )



    //console.log(`breakSection: ${breakSection}, position:${position}`); 


    return (sectionHtml)
}
