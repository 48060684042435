import React from 'react'
import Feature from './Feature';


export default function ListFeatures(props) {
    let {list} = props; 
    list = list? list:[]; 
    return (
        <div className="list">
            {
            list.map((element,index) =>( <Feature key={index} feature={element}/> ) )
            }
        </div>
    )
}
