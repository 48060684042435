import {React} from 'react'
import { useImagePath } from '../hooks/paths'
import style from "../css/NavBar.module.css";
import {useState,    useEffect} from 'react'

export default function NavItemMenu(props) {
    const {name,link,submenu, onClickItem,dropdown } = props
  
    const [showSubmenu, setShowSubmenu] = useState(false)


    const handlerMouseEvent = (show)=>{
            if(submenu){
                setShowSubmenu(show)
            }
    }


    return (
      
        <div className={style.menuItem}    
            onMouseEnter={() => handlerMouseEvent(true)}
            onMouseLeave={() => handlerMouseEvent(false)}
        >
            <div className={style.menuItemName}>
                <a href={link}>{name}</a>
                { dropdown && submenu ? 
                    <span className={`material-symbols-outlined ${style.submenuDropdownIcon}`}>arrow_drop_down</span>
                    :""
                }
            </div>

            {!dropdown &&  submenu  && 
                <div className={style.submenu} >
                    {
                      submenu.map((element, index) => (
                        <a key={index} href={element.link}>{element.name}</a>
                        ))
                    }
                </div>
            }
           
            {dropdown &&  showSubmenu && 
            <div className={style.submenuDropdown} >             
                   {
                    submenu.map((element, index) => (
                        <a key={index} href={element.link}>{element.name}</a>
                        ))
                   }
            </div>
            }
             
        </div>
    )
}
