//import logo from './logo.svg';
import './css/style-app.css';
import content from './content/content.js'
import LandingPage from './component/LandingPage';
import { useMediaQuery } from 'react-responsive';
import Loading from './component/Loading';
import {useState,    useEffect} from 'react'
import { getQuery } from './hooks/paths';
import { getWebData } from './hooks/services';
import {  BrowserRouter as Router,  useLocation,    useParams, useRouteMatch} from "react-router-dom";


/**
 * 
 * @returns It is a custom hooks, start with the word use
 */
 

function App() {
  const [webContent, setWebContent] =  useState(null); 

  useEffect(() => {
      getWebData((data)=>{   
        setWebContent(data);
      })
  }, [])

  return (
          <div>
          { !webContent ? <Loading/> 
                        : <LandingPage content={webContent}/>       
          }
          </div>
  )
}

export default App;
