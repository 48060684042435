import React from 'react'
import '../css/logo.css';
import { useImagePath } from '../hooks/paths';

export default function Loading() {
    console.log("useImagePath",useImagePath()); 
    return (
        <div className="logo">
            <img src={`${useImagePath()}/wim-b.svg`} className="ic-logo" alt="logo" />
        </div>
    )
}
