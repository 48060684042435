import { getMetaEndpoint, getQuery } from "./paths";
import axios from 'axios';
import content from '../content/content.js'



function getWebData(callback){
  const query = getQuery(); 
  console.debug("query",query); 
  if(query?.server==="file"){
      callback(content); 
      return; 
  }

  const endpoint = getMetaEndpoint(query); 


  console.log("endpoint", endpoint);   
  axios.get(endpoint)
    .then((response)=>{
      let data = response.data; 
      console.log( "data: ", data); 
      callback(data);
    })
    .catch(error => {         
      console.error("Catched: ", error)
    });
}

export{
    getWebData
}