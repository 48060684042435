import React from 'react';
import '../css/style-app.css';
import ButtonAction from './ButtonAction';
import style from "../css/Carousel.module.css";
import Carousel from './Carousel';

export default function ContainerCarouselRow(props) {

    const { title, subtitle, list } = props.section;
    const { message } = props.section;
    const { buttonAction } = props.section;
    const { buttonSecundary } = props.section;
    const { buttonUrl } = props.section;
    const { position } = props;

     //console.log(`containerRow: ${title}`, props.section);

     let classContainer = "container-row color-light";
     if (position % 2 !== 0) {
         classContainer = "container-row";
     }
 
     let classContainerSection = "container-section";
     if (position % 2 !== 0) {
         classContainerSection = "container-section-reverse"
     }
 
 
     const buttonHtml = buttonAction ? (<ButtonAction action={buttonAction} url={buttonUrl} />): "";
 
     const sectionHtml = (
         <div className={classContainer}>
             <div className={classContainerSection}>
                 <section className="section section-large">
                     <h1>{title}</h1>
                     <h2>{subtitle}</h2>
                     <p className="section-p">{message}</p>
                     <div>
                         {
                              buttonAction ? 
                              <ButtonAction action={buttonAction} url={buttonUrl} />
                              : ""
                         }
                     </div>
                 </section>
                
                  {list && 
                   <div className="section-small  fade-in-section">
                   <Carousel list={list} />
                   </div>
                       
                   }
                 
             </div>
         </div>
     )
 
 
 
     //console.log(`breakSection: ${breakSection}, position:${position}`); 
 
 
     return (sectionHtml)
 }
 