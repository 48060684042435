import React from 'react'
import style from "../css/Buttons.module.css";



export default function ButtonAction(props) {
    const urlDefault = "https://play.google.com/store/apps/details?id=com.campino.wikimenu&referrer=utm_source%3Dweb-default"
    const {action} = props; 
    let {url}  = props;
  
    const googlePlay = (
        <a className = {style.aButton} href ={url} target="_blank" rel="noreferrer noopener">
            <img className= {style.playButton} src="../home/img/google-play-badge.png" alt="install app"/>
        </a>
    );

    const actionButton=(
        <a className =  {style.aButton} href ={url} target="_blank" rel="noreferrer noopener">
            <div className= {style.wmButton}>{action}</div>
        </a> 
    )

    const actionWhatsApp=(
        <a className = {style.aButton}  href ={url} target="_blank" rel="noreferrer noopener">
            <div className= {style.wappButton}>{action}</div>
        </a> 
    )

    let buttonHtml=""
    if(action==="install"){
        url = url? url : urlDefault; 
        buttonHtml = googlePlay; 
    }else if(action.toLowerCase()=="whatsapp"){
        buttonHtml = actionWhatsApp;    
    }else if(action){
        buttonHtml = actionButton;    
    }

    return buttonHtml; 
}
