import React, { useState, useEffect } from 'react';
import styles from "../css/Carousel.module.css";

const Carousel = (props) => {
    const list = props.list; 
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % list.length);
        }, 3000); // Cambia la imagen cada 3 segundos
        return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }, [list.length]);

    const handleIndicatorClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className={styles.carousel}>
            <div className={styles.carouselInner} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {list.map((item, index) => (
                    <div key={index} className={styles.carouselItem}>
                        {item.title && (
                            <div className={styles.carouselItemTitle}>
                                {item.title}
                            </div>
                        )}
                        <img src={item.image} alt={`Imagen ${index + 1}`} />

                    </div>
                ))}
            </div>
            <div className={styles.indicators}>
                {list.map((_, index) => (
                    <div
                        key={index}
                        className={`${styles.indicator} ${index === currentIndex ? styles.active : ''}`}
                        onClick={() => handleIndicatorClick(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;