import React from 'react'

export default function Footer() {
    return (
       <footer className="footer color-footer">
                <p>https://wim.to</p>
                <p>wim ©2024</p> 
        </footer>
    )
}
