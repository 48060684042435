
function useImagePath() {
    const home = process.env.PUBLIC_URL; // remember it need be the same of package.json
    let imageUrl = `${home}/img`;
    //console.debug(imageUrl); 
    return imageUrl;
}


function getQuery() {
    const search = new URLSearchParams(window.location.search);
    let query = new URLSearchParams(search);
    const server = query.get("server")
    const name = query.get("name")
    return {
        server: server,
        name:name,
    };
}

function getMeta(name) {
    let result = document.querySelector(`meta[name='${name}']`)?.content
    return result;
}


function getMetaEndpoint(query) {
    if(query?.server){
          const server = query?.server; 
          let name = query?.name? query?.name:  server.replace("www.",""); 
          if(name.includes("https://")) name = name.replace("https://","")
          if(name.includes("http://")) name = name.replace("http://","")
          return `${server}/data/${name}`
    }

    let endpoint = document.querySelector(`meta[name='data']`)?.content
    console.debug("meta-data:", endpoint);
    if(!endpoint){
          const env = process.env.NODE_ENV;
        endpoint= (env==="production")
        ? "https://www.wiki-menu.app/data/wiki-menu.app"
        :"http://192.168.1.133:1337/data/192.168.1.133:1337";
    }

    return endpoint;
}



export {
    useImagePath, 
    getMeta, 
    getQuery, 
    getMetaEndpoint,
}