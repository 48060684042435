import React from 'react'
import style from "../css/Feature.module.css";

export default function Feature(props) {
    const {feature} = props; 
    let icon  = feature.icon;
    if(!icon){      
        icon = "priority"
    }
   
  //  <span class="material-symbols-outlined">storefront</span>  <i className={`${icon} fa-3x`}></i>
    return (
        <div className={style.feature}>
            <p  className={style.featureIcon}>
                <span className={`material-symbols-outlined ${style.featureIconSize}`}>{`${icon}`}</span>
            </p>
            <div className={style.featureText}>
                <h3>{feature.title}</h3>
                {feature.description && 
                <p>{feature.description}</p>
                }
            </div>
        </div>
    ); 
}
